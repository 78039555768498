import request from "../../assets/js/Request.js";
// import qs from 'qs'
// export const userLogin = (params) => {
//   return request.post("loginPc", qs.stringify(params),{headers: {'Content-Type':'application/x-www-form-urlencoded'}});
// };

// 发送验证码
export const spendCode = (params) => {
  return request.post("sendVerificationCode", params);
};
// 验证码登录
export const userLogin = (params) => {
  return request.post("loginPc", params);
};
// 微信登录
// export const weChatLogin = () => {
//   return request.get("wchatLoginPc");
// };
// 退出登录
export const outLogin = (params) => {
  return request.post("logout", params);
};
// PC注册
export const registerUserPc = (params) => {
  return request.post("registerUserPc", params);
};
// 判断登录状态
export const checkState = (params) => {
  return request.post("checkState", params);
};
// 绑定手机
export const bindingUser = (params) => {
  return request.post("bindingUser", params);
};