const TOKEN_KEY = "token-user";

const setToken = value => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(value));
};
const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

const getToken = () => {
  return JSON.parse(localStorage.getItem(TOKEN_KEY))
};
const hasToken = () => !!getToken();

export { setToken, getToken, removeToken, hasToken };
