const TOKEN_KEY = "token-judges";

const setTokenJ = value => {
  sessionStorage.setItem(TOKEN_KEY, JSON.stringify(value));
};
const removeTokenJ = () => {
  sessionStorage.removeItem(TOKEN_KEY);
};

const getTokenJ = () => {
  return JSON.parse(sessionStorage.getItem(TOKEN_KEY))
};
const hasTokenJ = () => !!getTokenJ();

export { setTokenJ, getTokenJ, removeTokenJ, hasTokenJ };
