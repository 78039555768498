let filters = {
  getLocalTime(nS) {
    return new Date(parseInt(nS) * 1000)
      .toLocaleString()
      .replace(/:\d{1,2}$/, " ");
  },
  cszbFilter(cszb) {
    let str = "";
    switch (cszb) {
      case 3:
        str = "血透组";
        break;
      case 4:
        str = "腹透组";
        break;
      case 5:
        str = "CRRT组";
        break;
      case 6:
        str = "技师组";
        break;
      default:
        break;
    }
    return str;
  },
};
export default filters;
