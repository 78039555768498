import axios from "axios";
import { Message } from "element-ui";

// 设置根路径
// const Request = axios.create({ baseURL: "http://testgyyc.gytcare.com/Wechat/""});
// 生产 https://www.gyycbj.com/Wechat/
// 测试 http://testgyyc.gytcare.com/Wechat/
// 判断是生产环境还是开发环境，设置接口根路径
let SERVERURL = "";
let myserve = window.location.origin.split("//")[1];
if (myserve.split(":")[0] == "localhost") {
  SERVERURL = "http://testgyyc.gytcare.com/Wechat/";
} else {
  SERVERURL = window.location.origin + "/Wechat/";
}
const Request = axios.create({
  baseURL: SERVERURL,
});
// 请求拦截器
Request.interceptors.request.use((config) => {
  let Authorization = localStorage.getItem('Authorization')
  if (Authorization) {
    config.headers.token = Authorization
  }
  return config;
});
// 响应拦截器
Request.interceptors.response.use(
  (res) => {
    if (res.status === 500) {
      return Message({
        message: "服务器错误",
        type: "error",
      });
    }
    // if (res.data.code != '0000') {
    //   return Message({
    //     message: res.data.msg,
    //     type: "error"
    //   });
    // }
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default Request;
