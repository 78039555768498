<template>
  <div id="app">
    <div class="pc_header" v-if="isUserPage">
      <!-- <p class="time">预赛视频作品报送截止日期为8月21日</p> -->
      <img
        src="https://jnds-static.oss-cn-beijing.aliyuncs.com/pcnavber.jpg"
        class="img1"
      />
      <img
        src="https://jnds-static.oss-cn-beijing.aliyuncs.com/iosnavber.jpg"
        class="img2"
      />
      <!-- <div class="IostoUpBtn" @click="upChange" v-if="isLogin">上传作品</div> -->
    </div>
    <div class="menu" v-if="isUserPage" ref="menu">
      <ul>
        <li
          v-for="(item, index) in menuList"
          :key="item.id"
          :class="item.name == glName ? 'active' : ''"
        >
          <a @click="routerChange(item, index)">{{ item.text }}</a>
        </li>
        <!-- <div class="toUpBtn" @click="upChange" v-if="isLogin">上传作品</div> -->
      </ul>
    </div>
    <router-view />
    <div class="banquan" v-if="footershow" style="color: #999">
      京ICP备2022011792
    </div>
    <div class="toup" v-if="toUpIsshow">
      <div
        class="clear"
        @click="
          () => {
            toUpIsshow = false;
          }
        "
      >
        x
      </div>
      &nbsp;
      <i class="dot"></i>
      &nbsp;
      <div class="toup-content">
        <span>大赛咨询</span>
        <br />
        <span>万老师</span>
        <span>13910648827</span>
        <span>wanwei@gytcare.com</span>
        <!-- <span>邹老师</span>
        <span>13071164003</span>
        <span>zouying@gytcare.com</span> -->
      </div>
      <a class="suspend_btn" @click="toLogin()">
        <span>报名参赛</span>
        <i class="el-icon-arrow-right"></i>
      </a>
      <a class="suspend_btn" @click="goUp()">
        <span>回到顶部</span>
        <i class="el-icon-arrow-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { checkState } from "@/views/API/login";
import { getCompetitionInfo } from "@/views/API/user";
import { hasToken, setToken } from "@/assets/js/storage";
export default {
  data() {
    return {
      isLogin: false,
      isUserPage: true,
      glName: "Home",
      menuList: [
        {
          id: 1,
          name: "Home",
          text: "大赛介绍",
        },
        {
          id: 5,
          name: "Login",
          text: "报名参赛",
        },
        {
          id: 4,
          name: "Judges",
          text: "评委登录",
        },
        {
          id: 3,
          name: "Template",
          text: "作品要求",
        },

        {
          id: 9,
          name: "Broadcast",
          text: "大赛直播",
        },
        {
          id: 8,
          name: "Marvellous",
          text: "精彩瞬间",
        },
        {
          id: 7,
          name: "WorkShow",
          text: "赛后教学",
        },
        {
          id: 2,
          name: "News",
          text: "新闻资讯",
        },
      ],
      toUpIsshow: true,
      footershow: true,
    };
  },
  created() {
    this.getphaseHandle();
    this.generateRandomString();
  },
  mounted() {},
  watch: {
    async $route(to) {
      const myarry = [
        "information",
        "jicengHome",
        "organization",
        "meeting",
        "picturelive",
        "hosts",
        "about",
      ];
      if (myarry.includes(to.name)) {
        this.isUserPage = false;
        this.toUpIsshow = false;
        this.footershow = false;
      }
      if (to.name == "Judges") {
        this.isUserPage = true;
      }
      let newpath = to.path.slice(1).split("/")[0];
      if (newpath == "JudgesPage") {
        this.isUserPage = false;
        this.toUpIsshow = false;
        return;
      }
      const res = await checkState();
      if (res.data.code == "0000") {
        this.toUpIsshow = false;
        this.menuList = [];
        this.menuList = [
          {
            id: 1,
            name: "Home",
            text: "大赛介绍",
          },
          {
            id: 8,
            name: "signup",
            text: "报名参赛",
          },
          {
            id: 6,
            name: "User",
            text: "个人中心",
          },
          {
            id: 3,
            name: "Template",
            text: "作品要求",
          },
          {
            id: 9,
            name: "Broadcast",
            text: "大赛直播",
          },
          {
            id: 4,
            name: "Marvellous",
            text: "精彩瞬间",
          },
          {
            id: 7,
            name: "WorkShow",
            text: "赛后教学",
          },
          {
            id: 2,
            name: "News",
            text: "新闻资讯",
          },
        ];
        // 登录成功
        if (hasToken()) {
        } else {
          setToken(res.data.data);
        }
      } else {
        if (newpath !== "JudgesPage" && newpath !== "jiceng") {
          this.toUpIsshow = true;
        }
        if (document.body.clientWidth < 540) {
          this.toUpIsshow = false;
          this.menuList = this.menuList.map((item) => {
            if (item.text != "评委登录") {
              return item;
            }
          });
          if (res.data.code == "4003") {
            return;
          }
          this.glName = newpath;
          let token = localStorage.getItem("Authorization")
            ? localStorage.getItem("Authorization")
            : "";
          return window.open(
            window.location.origin + "/Wechat/index?act=jnds&token=" + token,
            "_self"
          );
        }
      }
      this.glName = newpath;
    },
  },
  methods: {
    generateRandomString() {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // 定义可用字符集合

      for (let i = 0; i < 20; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length); // 生成0到字符集合长度减一之间的随机整数
        result += characters[randomIndex]; // 将随机字符添加到结果字符串
      }
      if (localStorage.getItem("Authorization")) return;
      localStorage.setItem("Authorization", result);
    },
    // 获取比赛期数
    async getphaseHandle() {
      const res = await getCompetitionInfo();
      if (res.data.code === "0000") {
        localStorage.setItem("phase", res.data.data.com_id);
        localStorage.setItem("endtime", res.data.data.end_time);
        localStorage.setItem("starttime", res.data.data.start_time);
      }
    },
    upChange() {
      this.$router.push({ name: "updateWork" });
    },
    routerChange(item, index) {
      this.glName = item.name;
      this.$router.push({ name: item.name });
      let menuDOM = this.$refs.menu;
      if (index < 3) {
        menuDOM.scrollLeft = 0;
      } else if (index == 3) {
        menuDOM.scrollLeft = 160;
      } else if (index == 4) {
        menuDOM.scrollLeft = 280;
      } else if (index == 5) {
        menuDOM.scrollLeft = 350;
      }
    },
    toLogin() {
      this.$router.push({ name: "Login" });
      document.documentElement.scrollTop = 300;
    },
    goUp() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss">
#app {
  .active {
    text-decoration: none;
    background: #2c3d94;
    a {
      color: #ffffff !important;
    }
  }
  .toup {
    position: fixed;
    bottom: 50px;
    z-index: 9;
    right: 50px;
    background-color: #2c3d94;
    width: 200px;
    padding: 6px;
    padding-bottom: 12px;
    @media screen and (max-width: 520px) {
      display: none;
    }
    .clear {
      position: absolute;
      right: 12px;
      top: 2px;
      color: #fff;
      cursor: pointer;
    }
    .dot {
      width: 8px;
      height: 8px;
      display: block;
      background: #fff;
      border-radius: 50%;
      margin: 0 auto;
    }
    .toup-content {
      width: 188px;
      margin: 0 auto;
      background: #fff;
      border-radius: 3px;
      padding: 15px 25px;
      box-sizing: border-box;
      span {
        line-height: 20px;
        font-size: 12px;
        display: block;
        text-align: center;
        color: #004593;
      }
    }
    .suspend_btn {
      span {
        line-height: 40px;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        position: relative;
        padding-right: 12px;
        color: #004593;
      }
      width: 188px;
      height: 40px;
      font-size: 14px;
      margin: 0 auto;
      background: #fff;
      display: block;
      margin-top: 6px;
      text-align: center;
      border-radius: 3px;
      cursor: pointer;
    }
  }
  .pc_header {
    width: 100%;
    position: relative;
    .time {
      position: absolute;
      left: 50%;
      bottom: 18%;
      color: #fff;
      letter-spacing: 5px;
      transform: translateX(-50%);
      @media screen and (max-width: 520px) {
        bottom: 8%;
        text-align: center;
        font-size: 12px;
      }
    }
    .IostoUpBtn {
      display: none;
      @media screen and (max-width: 520px) {
        display: block;
      }
      position: absolute;
      left: 50%;
      margin-left: -60px;
      width: 120px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      bottom: 30px;
      background: url("./assets/image/index-img/btn.webp") no-repeat;
      background-size: 118% 208%;
      background-position: center center;
      border-radius: 18px;
    }
    @media screen and (max-width: 520px) {
      height: 360px;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .img1 {
      display: block;
      @media screen and (max-width: 520px) {
        display: none;
      }
    }
    .img2 {
      display: none;
      @media screen and (max-width: 520px) {
        display: block;
      }
    }
  }
  .menu::-webkit-scrollbar {
    display: none;
  }
  .menu {
    background: #05258e;
    height: 66px;
    width: 100%;

    // overflow: hidden;
    @media screen and (max-width: 520px) {
      height: 50px;
      overflow-x: scroll;
      overflow-y: hidden;
    }
    ul {
      height: 100%;
      // width: 1200px;
      width: fit-content;
      margin: 0 auto;
      position: relative;
      @media screen and (max-width: 520px) {
        width: max-content;
      }
      .toUpBtn {
        @media screen and (max-width: 520px) {
          display: none;
        }
        position: absolute;
        right: 0;
        bottom: 10px;
        cursor: pointer;
        width: 155px;
        color: #ffffff;
        height: 40px;
        line-height: 40px;
        text-align: center;
        padding: 2px 0;
        border: 0;
        border-radius: 2px;
        background: url("./assets/image/index-img/btn.webp") no-repeat;
      }
      li {
        display: inline-block;
        height: 100%;
        position: relative;
        a {
          cursor: pointer;
          background-color: #ffffff00;
          padding: 0px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          text-align: center;
          height: 100%;
          color: #fff;
          @media screen and (max-width: 520px) {
            font-size: 13px;
            padding: 0 18px;
          }
        }
        span {
          cursor: pointer;
          position: absolute;
          right: 5px;
          bottom: 20px;
          width: 0px;
          height: 0px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 7px solid #fff;
        }
        .iosSpan {
          display: none;
          @media screen and (max-width: 520px) {
            display: block;
          }
        }
      }
    }
  }
  .banquan {
    height: 60px;
    background-color: #1c1128;
    color: #ffffff;
    text-align: center;
    line-height: 60px;
    font-size: 16px;
    @media screen and (max-width: 520px) {
      height: 40px;
      line-height: 40px;
      font-size: 12px;
    }
  }
}
</style>
