import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import filters from "./assets/js/filter"; //引入过滤器
import "./assets/css/global.css"; //全局样式
import {
  Upload,
  Dialog,
  Message,
  Button,
  Form,
  FormItem,
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  Select,
  Option,
  Table,
  TableColumn,
  Autocomplete,
  Progress,
  Pagination,
  Switch,
  Tooltip,
  MessageBox,
  Backtop,
  Image,
  Tabs,
  TabPane,
  Loading
} from "element-ui";

Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Button);
Vue.use(Image);
Vue.use(Backtop);
Vue.use(Tooltip);
Vue.use(Switch);
Vue.use(Pagination);
Vue.use(Progress);
Vue.use(Form);
Vue.use(Autocomplete);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(Table);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(TableColumn);
Vue.component(Message);
Vue.use(Loading);
Vue.config.productionTip = false;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
// 遍历过滤器
for (let key in filters) {
  Vue.filter(key, filters[key]);
}
// 滚动加载更多
Vue.directive("loadMore", {
  bind(el, binding) {
    // 获取element，定义scroll
    let select_dom = el.querySelector(".hospitalList .hospitalListMore");
    select_dom.addEventListener("scroll", function () {
      let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (height) {
        binding.value();
      }
    });
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
