import request from "../../assets/js/Request.js";

// 验证码登录
export const judgesLogin = (params) => {
    return request.post("judgesLogin", params);
  };
  // 验证登录状态
export const checkJudgeState = (params) => {
  return request.post("checkJudgeState", params);
};
// 退出登录
export const judgesLogout = (params) => {
  return request.post("judgesLogout", params);
};