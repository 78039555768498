// import axios from "axios";
import request from "../../assets/js/Request.js";
// 获取用户个人信息
export const getUserInfo = (params) => {
    return request.post(`getUserInfo`,params);
  };
// 更新用户个人信息
export const updateUserInfo = (params) => {
  return request.post(`updateUserInfo`,params);
};
// 获取报名参赛信息
export const getBmInfo = (params) => {
  return request.post(`getBmInfo`,params);
};
// 提交审核
export const setBaoming = (params) => {
  return request.post(`Baoming`,params);
};
// 获取阿里云凭证
export const getSTSToken = (params) => {
  return request.post(`getSTSToken`,params);
};
// 获取参赛作品
export const getEntryInformation = (params) => {
  return request.post(`getEntryInformation`,params);
};
// 获取比赛期数
export const getCompetitionInfo = (params) => {
  return request.post(`getCompetitionInfo`,params);
};
