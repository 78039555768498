import Vue from "vue";
import VueRouter from "vue-router";
// import {checkState} from '../views/API/login.js'
import { Message } from "element-ui";
import { setTokenJ } from "../assets/js/Storage-J";
import { setToken } from "../assets/js/storage";
import { checkState } from "../views/API/login";
import { checkJudgeState } from "../views/JudegeAPI/login";

Vue.use(VueRouter);
//路由导航冗余报错（路由重复）
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    redirect: "Home",
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import("../views/Home/Home.vue"),
    meta: {
      title: "技能竞赛(首页)",
    },
  },
  {
    path: "/jiceng",
    name: "jiceng",
    component: () => import("@/views/jiceng/index.vue"),
    meta: {
      title: "2023基层透析发展论坛",
    },
    redirect: "jiceng/jicengHome",
    children: [
      {
        path: "jicengHome",
        name: "jicengHome",
        component: (resolve) => require(["@/views/jiceng/Home.vue"], resolve),
        meta: {
          title: "2023基层透析发展论坛",
        },
      },
      {
        path: "information",
        name: "information",
        component: (resolve) => require(["@/views/jiceng/information.vue"], resolve),
        meta: {
          title: "大会信息",
        },
      },
      {
        path: "organization",
        name: "organization",
        component: (resolve) => require(["@/views/jiceng/organization.vue"], resolve),
        meta: {
          title: "组织机构",
        },
      },
      {
        path: "meeting",
        name: "meeting",
        component: (resolve) => require(["@/views/jiceng/meeting.vue"], resolve),
        meta: {
          title: "会议日程",
        },
      },
      {
        path: "picturelive",
        name: "picturelive",
        component: (resolve) => require(["@/views/jiceng/picturelive.vue"], resolve),
        meta: {
          title: "图片直播",
        },
      },
      {
        path: "hosts",
        name: "hosts",
        component: (resolve) => require(["@/views/jiceng/hosts.vue"], resolve),
        meta: {
          title: "讲者主持",
        },
      },
      {
        path: "about",
        name: "about",
        component: (resolve) => require(["@/views/jiceng/about.vue"], resolve),
        meta: {
          title: "联系我们",
        },
      },
    ],
  },
  {
    path: "/News",
    name: "News",
    component: () => import("../views/News/index.vue"),
    meta: {
      title: "新闻资讯",
    },
    redirect: "News/NewsInfo",
    children: [
      {
        path: "NewsInfo",
        name: "NewsInfo",
        component: () => import("../views/News/NewsInfo.vue"),
        meta: {
          title: "最新资讯",
        },
      },
      {
        path: "NewsDetails/:id",
        name: "NewsDetails",
        component: () => import("../views/News/Newsdetails.vue"),
        meta: {
          title: "作品详情",
        },
      },
    ],
  },
  {
    path: "/Template",
    name: "Template",
    component: () => import("../views/Template/Template.vue"),
    meta: {
      title: "作品要求",
    },
  },
  {
    path: "/Marvellous",
    name: "Marvellous",
    component: () => import("../views/Marvellous/marvellous.vue"),
    meta: {
      title: "精彩瞬间",
    },
  },
  {
    path: "/Broadcast",
    name: "Broadcast",
    component: () => import("../views/Broadcast/Broadcast.vue"),
    meta: {
      title: "大赛直播",
    },
  },
  {
    path: "/WorkShow",
    name: "WorkShow",
    component: () => import("../views/WorkShow/WorkShow.vue"),
    meta: {
      title: "作品展示",
    },
  },
  {
    path: "/updateWork",
    name: "updateWork",
    component: () => import("../views/updateWork/updateWork.vue"),
    meta: {
      title: "作品上传",
    },
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("../views/Login/login.vue"),
    meta: {
      title: "用户登录",
    },
  },
  {
    path: "/Binding",
    name: "Binding",
    component: () => import("../views/Login/binding.vue"),
    meta: {
      title: "绑定手机",
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/Signup/index.vue"),
    meta: {
      title: "报名参赛",
    },
  },
  {
    path: "/User",
    name: "User",
    component: () => import("../views/User/user.vue"),
    meta: {
      title: "个人中心",
    },
    redirect: "User/UserInfo",
    children: [
      {
        path: "UserInfo",
        name: "UserInfo",
        component: (resolve) => require(["@/views/User/userinfo.vue"], resolve),
        meta: {
          title: "个人信息",
        },
      },
      {
        path: "userWork",
        name: "userWork",
        component: (resolve) =>
          require(["@/views/User/userworks.vue"], resolve),
        meta: {
          title: "参赛作品",
        },
      },
    ],
  },
  {
    path: "/Judges",
    name: "Judges",
    component: () => import("../views/Judges/Judges.vue"),
    meta: {
      title: "评委登录",
    },
  },
  {
    path: "/JudgesPage",
    name: "JudgesPage",
    component: () => import("../views/JudgesPage/index.vue"),
    meta: {
      title: "评委页面",
    },
    // redirect: "JudgesPage/noScore",
    children: [
      {
        path: "allScore",
        name: "allScore",
        component: (resolve) =>
          require(["@/views/JudgesPage/allScore.vue"], resolve),
        meta: {
          title: "全部作品",
        },
      },
      {
        path: "noScore",
        name: "noScore",
        component: (resolve) =>
          require(["@/views/JudgesPage/noScore.vue"], resolve),
        meta: {
          title: "未评分作品",
        },
      },
      {
        path: "yesScore",
        name: "yesScore",
        component: (resolve) =>
          require(["@/views/JudgesPage/yesScore.vue"], resolve),
        meta: {
          title: "已评分作品",
        },
      },
      {
        path: "noScoreDetails",
        name: "noScoreDetails",
        component: (resolve) =>
          require(["@/views/JudgesPage/noScoreDetails.vue"], resolve),
        meta: {
          title: "视频详情",
        },
      },
      {
        path: "yesScoreDetails",
        name: "yesScoreDetails",
        component: (resolve) =>
          require(["@/views/JudgesPage/yesScoreDetails.vue"], resolve),
        meta: {
          title: "打分详情",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
// 路由前置守卫
router.beforeEach(async (to, from, next) => {
  if (to.matched[0].name == "User" || to.name == "signup") {
    const res = await checkState();
    // res.data.code == "0000"
    if (res.data.code == "0000") {
      setToken(res.data.data);
      return next();
    }
    Message({
      message: "未查询到登录信息",
      type: "error",
    });
    return next({ name: "Login" });
  }
  if (to.matched[0].name == "JudgesPage") {
    const res = await checkJudgeState();
    // res.data.code == "0000"
    if (res.data.code == "0000") {
      setTokenJ(res.data.data);
      return next();
    }
    Message({
      message: "未查询到登录信息",
      type: "error",
    });
    return next({ name: "Judges" });
  }
  next();
});


export default router;
